// Global Methods


export function raw (data, keep='\\w') {
  const pattern = new RegExp(`[^${keep}]`, 'gi');
  if (data!==null) {
    data = data.replace(pattern, '');
    if (keep=='\\d') {
      data = parseInt(data);
    }
  }
  return data;
}

export function piper (data, token='|') {
  return data===null ? null : typeof data == 'string' ? _.isEmpty(data) ? [] : _.map(_.split(data, token), v => _.trim(v)) : _.join(data, token);
}

export function getMeta ($) {
  let path = $.$route.path;
  path = path.replace(/^\/|\/$/, '').replace(/\//, '.');
  const items = _.split(path, '.');
  const tree = _.cloneDeep($.$vuetify.locale.messages[$.$vuetify.locale.current].meta);
  const meta = _.reduceRight(items, (meta, item) => {
    const m = _.get(tree, path);
    if (!meta) {
      if (!!m) {
        meta = m;
      }else{
        path = _.replace(path, new RegExp(`\.?${item}`), '');
      }
    }
    return meta;
  }, null);
  let { base, title, titleTemplate } = _.merge(tree, meta);
  if (title.includes('%b')) {
    title = title.replace('%b', base);
  }else{
    title = titleTemplate.replace('%b', base).replace('%s', title);
  }
  return { title };
}

export function formatText (text, data={}) {
  const reg = {
    link: /\[([^\[]+)\]\(([^\)]+)\)(\^?)/gim, // [text](link) ^ can be added at end to set the target as 'blank'
    bold: /\*([^\*]+)\*/gim, // *bold*
    emphasis: /\*\*([^\*]+)\*\*/gim, // **emphasis**
    nowrap: /--([^\*]+)--/gim, // --nowrap--
    br: /\n/gim,
    data: /\{\{([^\*\}]+)\}\}/gim, // {{var}}
  }
  return !!text ? text
    .replace(reg.data, (match, $1) => {
      return _.get(data, $1, '');
    })
    .replace(reg.emphasis, "<em>$1</em>")
    .replace(reg.bold, "<b>$1</b>")
    .replace(reg.nowrap, "<span class='text-no-wrap'>$1</span>")
    .replace(reg.br, "<br/>") : '';
}

export const parseI18n = function (controller, data, scope=null) {
  const patterns = { 
    en: { 
      suffix: '', 
      r: /.*(?=En)/ 
    }, 
    pt: {
      suffix: 'Pt',
      r: /.*(?=Pt)/
    }, 
    es: {
      suffix: 'Es',
      r: /.*(?=Es)/ 
    }
  }
  const tooltipPattern = /!(?<tooltip>(?<key>.+)=(?<tip>.+))!/;

  const terms = _.reduce(_.cloneDeep(data), (terms, value, key) => {
    if (_.some(patterns, (p) => p.r.test(key))) {
      const base = _.reduce(patterns, (base, p) => {
        return base.replace(p.suffix, '');
      }, key);
      terms = _.union(terms, [base]);
    }
    return terms;
  }, []);
  const messages = {};
  _.each(terms, t => {
    const name = !!scope ? scope + '.' + t : t;
    messages[name] = {}
    _.each(patterns, (p,l) => {
      let translation = data[t+p.suffix];
      try {
        if (tooltipPattern.test(translation)) {
          let tooltip = {
            key: (!!scope ? scope : t)+'.tooltip',
            label: null,
            text: null
          };
          if (!(tooltip.key in messages)) messages[tooltip.key] = {}
          translation = translation.replace(tooltipPattern, (match, $1, $2, $3) => {
            tooltip.label = $2;
            tooltip.text = $3;
            return '';
          });
          data.tooltip = tooltip;
          messages[tooltip.key][l] = tooltip.text;
        }
      } catch (error) {
        console.warn(error);
      }
      messages[name][l] = !!translation ? translation : data[t];
    });
    data[t] = name;
  });
  _.each(messages, (message, m) => {
    _.each(controller.messages, (lang, l) => {
      _.set(controller.messages[l], m, message[l]=='' ? ' ' : message[l]);
    });
  });

  return data;
}

export const isValid = function (value, rules, $) {
  return _.every(rules, (rule) => {
    // if (rule(value, $)!=true) console.log('invalid', value, $);
    return rule(value, $)==true;
  });
}

export function toDataURL (url) { 
  return fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject(error)
      reader.readAsDataURL(blob)
    }))
}

export function getAppConfig (url) {
  console.log('getAppConfig =>', url);
  return fetch(url).then(response => response.json());
}

export function versionStatus (installed, version) {
  let status = 'updated';
  if (!isVersionUpToDate(installed, version.block)) {
    status = 'blocked';
  }else if (!isVersionUpToDate(installed, version.current)) {
    status = 'outdated';
  }
  return status;
}

export function isVersionUpToDate(version1, version2) {
  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1 = v1Parts[i] || 0;
      const v2 = v2Parts[i] || 0;

      if (v1 > v2) {
          return true;
      } else if (v1 < v2) {
          return false;
      }
  }

  return true;
}


export function breakpoint (min=null, max=null, log=false) {
  const sizes = {
    'xs': 600, 
    'sm': 960, 
    'md': 1264, 
    'lg': 1904,
    'xl': 4000
  };
  const w = window.innerWidth;
  // console.log(window.innerWidth > sizes[min], window.innerWidth < sizes[max]);
  let s, e, r;
  if (!!min||!!max) {
    if (!_.isNil(min)) {
      s = _.has(sizes, min) ? w > sizes[min] : min;
      if (!_.isNil(max)) {
        e = _.has(sizes, max) ? w < sizes[max] : max;
        r = s && e;
      }else{
        r = s;
      }
    }else{
      r = _.has(sizes, max) ? w < sizes[max] : max;
    }
  }else{
    r = _.findKey(sizes, size => size>=w);
  }
  if (log) console.log(s, e, r);
  return r;
}

export function toast (text, timeout=7000, button, color) {
  const active = !!text;
  if (!button||button===false) {
    button = {
      toggle: false,
      text: 'Ok',
      action: false
    };
  }
  if (!color) {
    color = 'black';
  }
  this.$refs.toast.controller = {
    active,
    text,
    timeout,
    button,
    color
  };
}

export function logout (ghost=null) {
  storage.delete('auth').then(() => {
    storage.delete('user').then(() => {
      storage.delete('profile').then(() => {
        storage.delete('settings').then(() => {
          storage.delete('vuex').then(async () => {
            if (!!ghost) {
              await storage.set('user', ghost.user);
              await storage.set('auth', ghost.auth);
              window.location.reload();
            }else{
              window.location = '/hello';
            }
          });
        });
      });
    });
  })
}

export function trackEvent (action, category, label, value) {
  this.$gtag.event(action, {
    'event_category': category,
    'event_label': label,
    'value': value
  })
}

export function trackError ($, error, msg, toast) {
  // let timer = 7000;
  // if (!msg) {
  //   if (this.isOnline) {
  //     console.log('Network/Server Error', error);
  //     msg = 'Deu erro aqui! Vamos investigar o problema 😊';
  //   }else{
  //     msg = 'Sem conexão com a internet.';
  //     timer = -1;
  //     console.log('Offline');
  //   }
  // }
  // toast = !!toast ? toast : false;
  // if (toast||!this.isOnline) {
  //   this.toggleToast(
  //     true,
  //     msg,
  //     timer,
  //     false
  //   );
  // }
  // if a `message` property exists or not
  // const exception = !this.isOnline ? 'Offline' : _.isNil(msg) ? _.has(error, 'message') ? error.message : error : msg;
  const exception = _.isNil(msg) ? _.has(error, 'message') ? error.message : error : msg;
  console.error(error);
  $.$gtag.exception({ description: exception });
}

export function isInAppBrowser () {
  return navigator.userAgent.includes("Instagram") || navigator.userAgent.includes("FBAV");
}

export const storage = {
  set (key, value, type='local') {
    type = type + 'Storage';
    return Promise.resolve().then(() => {
      if (_.isObject(value)) value = JSON.stringify(value);
      window[type].setItem(key, value);
    });
  },
  get (key, type='local') {
    type = type + 'Storage';
    return Promise.resolve().then(async () => {
      let value = await window[type].getItem(key);
      if (_.startsWith(value, '{')||_.startsWith(value, '[')||value=='true'||value=='false') value = JSON.parse(value);
      return value;
    });
  },
  delete (key, type='local') {
    type = type + 'Storage';
    return Promise.resolve().then(function () {
      window[type].removeItem(key);
    });
  },
}